// グループ会社・拠点一覧
//
interface Base {
  name: string
  thumbnail: string
  thumbnailClass: string
  link: string
  service: string
  zipcode: string
  location: string
  tel: string
  fax?: string
  tbody?: {
    th: string
    td: {
      zipcode: string
      location: string
      mapLink: string
      phoneNumber: string
      phoneNumber2?: string
    }
  }[]
}
const Base1: Base = {
  name: '株式会社ミライル',
  thumbnail: '/company/img/base01.png',
  thumbnailClass: 'thumb-border',
  link: 'https://miraillu.co.jp/',
  service:
    '労働者派遣事業、有料職業紹介事業（ハイキャリアエージェントサービス含む）、採用支援事業、BPO事業、ソフトウェア開発、スマートフォンアプリ開発、DX事業',
  zipcode: '〒110-0016',
  location: '東京都台東区台東1-1-14 D’s VARIE秋葉原ビル5階',
  tel: '03-6284-2120',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒110-0016',
        location: '東京都台東区台東1-1-14 D’s VARIE秋葉原ビル5階',
        mapLink: 'https://maps.app.goo.gl/owUnvSirnEtSoYFG7',
        phoneNumber: '03-6284-2120',
      },
    },
    {
      th: '札幌第一支店（旧CR）',
      td: {
        zipcode: '〒060-0002',
        location: '北海道札幌市中央区北2条西3-1-21 札幌北2条ビル7階',
        mapLink: 'https://maps.app.goo.gl/yvt5uWWuhs4ycYwAA',
        phoneNumber: '050-5784-3012',
      },
    },
    {
      th: '札幌第二支店（旧JOBS）',
      td: {
        zipcode: '〒060-0002',
        location: '北海道札幌市中央区北2条西3-1-21 札幌北2条ビル7階',
        mapLink: 'https://maps.app.goo.gl/yvt5uWWuhs4ycYwAA',
        phoneNumber: '050-1782-1580',
      },
    },
    {
      th: '札幌コンタクトセンター',
      td: {
        zipcode: '〒060-0032',
        location: '北海道札幌市中央区北二条東2丁目1-16 キョウエイ札幌ビル5階',
        mapLink: 'https://maps.app.goo.gl/EEKpwwAoqahxvwU69',
        phoneNumber: '0120-553-647',
      },
    },
    {
      th: '新潟支店',
      td: {
        zipcode: '〒950-0088',
        location:
          '新潟県新潟市中央区万代1-4-33 損保ジャパン・新潟セントラルビル5階',
        mapLink: 'https://maps.app.goo.gl/ehqZKeLxVZ2d2N7x7',
        phoneNumber: '050-5784-3016',
      },
    },
    {
      th: '仙台支店',
      td: {
        zipcode: '〒980-0014',
        location: '宮城県仙台市青葉区本町1-3-9 第六広瀬ビル4階',
        mapLink: 'https://maps.app.goo.gl/h7qjSTrHJJ7Aw4Ge9',
        phoneNumber: '050-5784-3014',
      },
    },
    {
      th: '水戸支店',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2丁目1-20 井門水戸ビル5階',
        mapLink: 'https://maps.app.goo.gl/uCEGW34a1Bz7nVtg8',
        phoneNumber: '050-1782-1516',
      },
    },
    {
      th: '新宿第一支店（旧CR）',
      td: {
        zipcode: '〒160-0022',
        location: '東京都新宿区新宿2-3-13 大橋ビル4階',
        mapLink: 'https://maps.app.goo.gl/LwyVh2RQFmgQiML1A',
        phoneNumber: '050-5784-3033',
      },
    },
    {
      th: '新宿第二支店（旧JOBS）',
      td: {
        zipcode: '〒160-0022',
        location: '東京都新宿区新宿2-3-13 大橋ビル4階',
        mapLink: 'https://maps.app.goo.gl/LwyVh2RQFmgQiML1A',
        phoneNumber: '050-5784-7120',
      },
    },
    {
      th: '大宮支店',
      td: {
        zipcode: '〒330-0845',
        location: '埼玉県さいたま市大宮区仲町2-25 松亀プレジデントビル205',
        mapLink: 'https://maps.app.goo.gl/WC58m8xEbzufFFzJ7',
        phoneNumber: '050-1782-1510',
      },
    },
    {
      th: '西船橋支店',
      td: {
        zipcode: '〒273-0032',
        location: '千葉県船橋市葛飾町2-381-4 サンモール五番館202',
        mapLink: 'https://maps.app.goo.gl/2bZsM7M8yoMAqSWm6',
        phoneNumber: '050-1782-1570',
      },
    },
    {
      th: '横浜支店',
      td: {
        zipcode: '〒221-0056',
        location: '神奈川県横浜市神奈川区金港町6-14 ステートビル横浜5階',
        mapLink: 'https://maps.app.goo.gl/WWdFwFRD16ataqWWA',
        phoneNumber: '050-5497-8071',
      },
    },
    {
      th: '大阪第一支店（旧CR）',
      td: {
        zipcode: '〒530-0051',
        location: '大阪府大阪市北区太融寺町5-13 東梅田パークビル9階',
        mapLink: 'https://maps.app.goo.gl/FGsxvHL62ppgAUYn9',
        phoneNumber: '050-5784-3018',
      },
    },
    {
      th: '大阪第二支店（旧JOBS）',
      td: {
        zipcode: '〒530-0051',
        location: '大阪府大阪市北区太融寺町5-13 東梅田パークビル9階',
        mapLink: 'https://maps.app.goo.gl/FGsxvHL62ppgAUYn9',
        phoneNumber: '050-5784-7116',
      },
    },
    {
      th: '神戸支店',
      td: {
        zipcode: '〒651-0095',
        location: '兵庫県神戸市中央区旭通2-7-8 インテリアビル3階',
        mapLink: 'https://maps.app.goo.gl/kannr6Y1Kk7mLvWr5',
        phoneNumber: '050-5497-8619',
      },
    },
    {
      th: '福岡第一支店（旧CR）',
      td: {
        zipcode: '〒810-0002',
        location: '福岡県福岡市中央区西中洲12-33 福岡大同生命ビル11階',
        mapLink: 'https://maps.app.goo.gl/3kUDRX3hicCtjaLcA',
        phoneNumber: '050-5784-3024',
      },
    },
    {
      th: '福岡第二支店（旧JOBS）',
      td: {
        zipcode: '〒810-0002',
        location: '福岡県福岡市中央区西中洲12-33 福岡大同生命ビル11階',
        mapLink: 'https://maps.app.goo.gl/3kUDRX3hicCtjaLcA',
        phoneNumber: '050-5784-7114',
      },
    },
    {
      th: '沖縄支店',
      td: {
        zipcode: '〒900-0032',
        location: '沖縄県那覇市松山1-1-19 JPR那覇ビル5階',
        mapLink: 'https://maps.app.goo.gl/YkaJDFd84cNftYra7',
        phoneNumber: '050-5784-3028',
      },
    },
  ],
}
const Base4: Base = {
  name: '株式会社プロテクス',
  thumbnail: '/company/img/base04.png',
  thumbnailClass: '',
  link: 'https://crprotex.co.jp/',
  service:
    '工場内製造業務、工場内物流業務、倉庫運営、他関連業務、第一種貨物利用運送事業、人材派遣事業',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-6302-0810',
  fax: '03-3345-2771',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
        mapLink: 'https://maps.app.goo.gl/1VmpAgxNtW8ftpGS9',
        phoneNumber: '03-6302-0810',
      },
    },
    {
      th: '埼玉営業所',
      td: {
        zipcode: '〒369-0307',
        location: '埼玉県児玉郡上里町嘉美1600-11',
        mapLink: 'https://maps.app.goo.gl/F3XGHN78U1ahR3bY8',
        phoneNumber: '0495-35-0271',
      },
    },
    {
      th: '浜松支店',
      td: {
        zipcode: '〒430-0935',
        location: '静岡県浜松市中央区区伝馬町312-32 MIテラス浜松3階',
        mapLink: 'https://goo.gl/maps/AKDYhiqdc2Bghmpm8',
        phoneNumber: '053-413-1101',
      },
    },
    {
      th: '静岡営業所',
      td: {
        zipcode: '〒425-0006',
        location: '静岡県焼津市関方16-1',
        mapLink: 'https://maps.app.goo.gl/6ogppkxm9nPRQaCQ8',
        phoneNumber: '054-621-3618',
      },
    },
    {
      th: '伊丹営業所',
      td: {
        zipcode: '〒664-0831',
        location: '兵庫県伊丹市北伊丹9-67',
        mapLink: 'https://maps.app.goo.gl/QUYebjiLGEoKXrSG6',
        phoneNumber: '072-782-6005',
      },
    },
    {
      th: '香川営業所',
      td: {
        zipcode: '〒769-1604',
        location: '香川県観音寺市豊浜町箕浦2518-8',
        mapLink: 'https://maps.app.goo.gl/6M5Xxn8qitPNox3M8',
        phoneNumber: '0875-56-3571',
      },
    },
  ],
}
const Base5: Base = {
  name: '株式会社パレット',
  thumbnail: '/company/img/base06.png',
  thumbnailClass: '',
  link: 'https://crpalette.co.jp/',
  service: '障がい者福祉サービス事業',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '050-5497-9488',
  fax: '03-5992-5288',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
        mapLink: 'https://maps.app.goo.gl/Ju5rXpQ2RmikaTMd6',
        phoneNumber: '050-5497-9488',
      },
    },
    {
      th: '事務センター',
      td: {
        zipcode: '〒330-0843',
        location: '埼玉県さいたま市大宮区吉敷町1-62 マレーS・Tビル 3階',
        mapLink: 'https://maps.app.goo.gl/AK8XMagQ2KQVuBgH9',
        phoneNumber: '048-640-3570',
      },
    },
    {
      th: '水戸アビリティBPOセンター',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2-9-12 第3プリンスビル1階',
        mapLink: 'https://maps.app.goo.gl/u2Cb1V7egFXZjH3n7',
        phoneNumber: '029-302-7723',
      },
    },
    {
      th: '土浦アビリティBPOセンター',
      td: {
        zipcode: '〒300-0036',
        location: '茨城県土浦市大和町9-3 ウララ3ビル 4階',
        mapLink: 'https://maps.app.goo.gl/kVMLD1XUyLzejfPP7',
        phoneNumber: '029-825-5530',
      },
    },
    {
      th: 'Canvas水戸オフィス',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2-9-12 第3プリンスビル1階',
        mapLink: 'https://maps.app.goo.gl/u2Cb1V7egFXZjH3n7',
        phoneNumber: '029-302-0521',
      },
    },
    {
      th: 'Canvas土浦オフィス',
      td: {
        zipcode: '〒300-0036',
        location: '茨城県土浦市大和町9-3 ウララ3ビル 5階',
        mapLink: 'https://maps.app.goo.gl/kVMLD1XUyLzejfPP7',
        phoneNumber: '029-825-5530',
      },
    },
    {
      th: 'Canvas大宮オフィス',
      td: {
        zipcode: '〒330-0843',
        location: '埼玉県さいたま市大宮区吉敷町1-62 マレーS・Tビル 3階',
        mapLink: 'https://maps.app.goo.gl/AK8XMagQ2KQVuBgH9',
        phoneNumber: '048-640-3570',
      },
    },
    {
      th: 'カラーズ水戸オフィス',
      td: {
        zipcode: '〒310-0803',
        location: '茨城県水戸市城南2-9-12 第3プリンスビル2階',
        mapLink: 'https://maps.app.goo.gl/bvhbJqh4VMcUeEAn7',
        phoneNumber: '029-302-7721',
      },
    },
    {
      th: 'カラーズ武蔵境オフィス',
      td: {
        zipcode: '〒180-0022',
        location: '東京都武蔵野市境2-2-19 イニシャルハウス3階',
        mapLink: 'https://maps.app.goo.gl/FoTJuvuYyDaX8Lj5A',
        phoneNumber: '0422-59-0851',
      },
    },
    {
      th: 'カラーズ・ラボ札幌',
      td: {
        zipcode: '〒060-0005',
        location: '北海道札幌市中央区北5条西24丁目3-1 カレラ円山ビル6階',
        mapLink: 'https://maps.app.goo.gl/SqA5ShkMYczEbcjb8',
        phoneNumber: '011-213-7081',
      },
    },
    {
      th: 'カラーズ・ラボ新潟',
      td: {
        zipcode: '〒950-0087',
        location: '新潟県新潟市中央区東大通1丁目7-7 IMA3ビル3階',
        mapLink: 'https://maps.app.goo.gl/c7SE1vm3WFvnHBUbA',
        phoneNumber: '025-384-4650',
      },
    },
    {
      th: 'カラーズ・ラボ仙台',
      td: {
        zipcode: '〒980-0014',
        location: '宮城県仙台市青葉区本町1-13-24 錦ビル4階',
        mapLink: 'https://maps.app.goo.gl/Y2FZSix2zkChzCWf7',
        phoneNumber: '022-797-1427',
      },
    },
    {
      th: 'カラーズ・ラボ名古屋',
      td: {
        zipcode: '〒460-0002',
        location: '愛知県名古屋市中区丸の内2-12-26 丸の内セントラルビル2階',
        mapLink: 'https://maps.app.goo.gl/hzcSVdLaWbcsQaZg9',
        phoneNumber: '052-684-9511',
      },
    },
    {
      th: 'カラーズ・ラボ岡山',
      td: {
        zipcode: '〒700-0971',
        location: '岡山県岡山市北区野田2-4-1シティーセンタービル1階',
        mapLink: 'https://maps.app.goo.gl/1kvSNGjH8quMuuJG7',
        phoneNumber: '086-259-1920',
      },
    },
    {
      th: 'カラーズ・ラボ広島',
      td: {
        zipcode: '〒730-0051',
        location: '広島県広島市中区大手町2丁目8-5 合人社広島大手町ビル7階',
        mapLink: 'https://maps.app.goo.gl/gFtzFfRCZem6iu8U6',
        phoneNumber: '082-258-2295',
      },
    },
    {
      th: 'カラーズ・ラボ福岡',
      td: {
        zipcode: '〒810-0041',
        location:
          '福岡県福岡市中央区大名2丁目4-30 西鉄赤坂ビル4階（地下鉄空港線赤坂駅直結）',
        mapLink: 'https://maps.app.goo.gl/absyLcRwD2Meet356',
        phoneNumber: '092-791-6872',
      },
    },
  ],
}
const Base6: Base = {
  name: 'CRGインベストメント株式会社',
  thumbnail: '/company/img/base07.png',
  thumbnailClass: '',
  link: 'https://crg-ivm.co.jp/',
  service: 'M&A・投資事業',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-5989-0857',
  fax: '03-3345-2771',
  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
        mapLink: 'https://maps.app.goo.gl/Z6hu3RYTmA1gRe5r9',
        phoneNumber: '03-5989-0857',
      },
    },
  ],
}
const Base7: Base = {
  name: '株式会社オシエテ',
  thumbnail: '/company/img/base08.png',
  thumbnailClass: 'thumb-border',
  link: 'https://ociete.co.jp/',
  service: '法人向けオンライン通訳サービス「オシエテ」',
  zipcode: '〒163-0437',
  location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
  tel: '03-6868-8786',

  tbody: [
    {
      th: '本社',
      td: {
        zipcode: '〒163-0437',
        location: '東京都新宿区西新宿2-1-1 新宿三井ビルディング37階',
        mapLink: 'https://goo.gl/maps/tXLZn2ngRUC2',
        phoneNumber: '03-6868-8786',
      },
    },
    {
      th: '営業所',
      td: {
        zipcode: '〒151-0053',
        location: '東京都渋谷区代々木1-25-5 BIZSMART代々木339',
        mapLink: 'https://maps.app.goo.gl/FtPyh9znYK8knBhk6',
        phoneNumber: '03-6868-8786',
      },
    },
  ],
}

const Base8: Base = {
  name: '株式会社クレイリッシュ',
  thumbnail: '/company/img/base09.png',
  thumbnailClass: 'thumb-border',
  link: 'https://901901.jp/',
  service: '事業者向けの金融事業',
  zipcode: '〒337-0051',
  location: '埼玉県さいたま市見沼区東大宮5-4-2 ロンポワンビル1階',
  tel: '048-682-2300',
  fax: '048-682-2302',
}

export { Base1, Base4, Base5, Base6, Base7, Base8 }
